import React, { ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Instance as PopperInstance, createPopper } from '@popperjs/core';
import styled from 'styled-components';

export interface TriggerArgs {
    ref: any;
    open: () => void;
    close: () => void;
    toggle: () => void;
}

export interface TriggerFn {
    (a: TriggerArgs): ReactNode;
}

export interface TooltipProps {
    children: TriggerFn;
    content: ReactNode;
}

let INCREMENTAL_ID = 0;

export function Tooltip({ content, children }: TooltipProps) {
    const [id] = useState(() => 'tooltip--' + INCREMENTAL_ID++);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<PopperInstance>();

    const [isOpen, setOpen] = useState(false);
    const open = useCallback(() => setOpen(true), []);
    const close = useCallback(() => setOpen(false), []);
    const toggle = useCallback(() => setOpen(o => !o), []);

    useLayoutEffect(() => {
        triggerRef.current!.setAttribute('aria-describedby', id);
    }, []);

    useLayoutEffect(() => {
        if (!isOpen) return;

        popperRef.current = createPopper(triggerRef.current!, tooltipRef.current!, {
            placement: 'left',
            modifiers: [
                { name: 'offset', options: { offset: [0, 10] } },
                {
                    name: 'arrow',
                    options: {
                        element: arrowRef.current,
                    },
                },
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['top', 'bottom'],
                    },
                },
            ],
        });

        return () => {
            if (popperRef.current) {
                popperRef.current.destroy();
                popperRef.current = undefined;
            }
        };
    }, [isOpen]);

    return (
        <>
            {children({ ref: triggerRef, open, close, toggle })}
            <TooltipContainer id={id} ref={tooltipRef} role="tooltip" className={isOpen ? 'open' : ''}>
                {content}
                <Arrow ref={arrowRef} />
            </TooltipContainer>
        </>
    );
}

const TooltipContainer = styled.div`
    display: none;
    background: #fafafa;
    padding: 1rem;
    width: 250px;
    max-width: 90vw;
    border-radius: 4px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    &.open {
        display: block;
    }
`;

const Arrow = styled.div`
    &,
    &::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
    }

    & {
        visibility: hidden;
    }

    &::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    [data-popper-placement^='top'] > & {
        bottom: -4px;
    }

    [data-popper-placement^='bottom'] > & {
        top: -4px;
    }

    [data-popper-placement^='right'] > & {
        left: -4px;
    }

    [data-popper-placement^='left'] > & {
        right: -4px;
    }
`;
