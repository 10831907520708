import { ITreatmentPriceGroup } from '../../models/Pricing';
import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../styles/config';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandableDiv } from '../ExpandableDiv';
import { useAccordion } from '../util/useAccordion';
import { Price } from './Price';

// STYLES ---------------------------------

const TitleButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background: ${colors.blue};
    color: ${colors.white};
    font-size: 22px;
    font-weight: 300;
    font-family: ${fonts.workSans}, sans-serif;
    border: none;
    width: 100%;
    line-height: 1.4em;

    svg {
        font-size: 14px;
    }
`;

// ----------------------------------------

export function PriceTableGroup({ title, prices }: ITreatmentPriceGroup): JSX.Element {
    const { expanded, toggle, buttonProps, regionProps } = useAccordion(true, 'PriceTableGroup');

    return (
        <>
            <h2>
                <TitleButton {...buttonProps} onClick={toggle}>
                    <span>{title}</span>
                    <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronUp} />
                </TitleButton>
            </h2>
            <ExpandableDiv isExpanded={expanded} {...regionProps}>
                {prices.map(({ treatment, price, description, isFromPrice, isIndividuallyPriced }) => (
                    <Price
                        key={treatment}
                        treatment={treatment}
                        price={price}
                        description={description}
                        isFromPrice={isFromPrice}
                        isIndividuallyPriced={isIndividuallyPriced}
                    />
                ))}
            </ExpandableDiv>
        </>
    );
}
